<template>
  <div class="mission-info">
    <!-- <el-breadcrumb separator-class="el-icon-arrow-right" style='margin-bottom:20px'>
      <el-breadcrumb-item >资源库管理</el-breadcrumb-item>
      <el-breadcrumb-item to='/zykgl/mission'>宣教管理</el-breadcrumb-item>
      <el-breadcrumb-item>宣教详情</el-breadcrumb-item>
    </el-breadcrumb>-->
    <div class="title">宣教名称：{{form.workName}}</div>
    <div class="name mt10">
      {{form.createTime}}
      <div class="times" style="margin-left:30%">
        宣教分类：{{form.workTypeName}}
        {{form.workTypeSecName?('~ '+form.workTypeSecName):' '}}
        {{form.workTypeThreeName?('~ '+form.workTypeThreeName):' '}}
        {{form.workTypeFourName?('~ '+form.workTypeFourName):' '}}
        {{form.workTypeFiveName?('~ '+form.workTypeFiveName):''}}
      </div>
    </div>
    <div v-if="form.type==3" class="mt20" v-html="form.workContent"></div>
    <template v-else>
      <div id="abc" class="video-js mt20" style="width:80%;height:80%"></div>
    </template>
  </div>
</template>
<script>
import TinyMce from "@/components/tiny-mce";
export default {
  name: "missionInfo",
  data() {
    return {
      form: {},
      dataListLoading: false,
    };
  },
  async mounted() {
    let query = this.$route.query;
    if (query.id) {
      this.dataListLoading = true;
      await this.init(query.id);
    }
  },
  methods: {
    async init(id) {
      const { data: res } = await this.$httpAes({
        url: this.$httpAes.adornUrl("/missionaryWork/getMissionaryWork"),
        method: "post",
        params: { stringParam1: id },
      });
      if (res.status) {
        // res.data.forEach(v=>{
        // this.typeList.forEach(v=>{
        //   if(v.value==res.data.noticeType){
        //      res.data.typeName = v.name

        // }})
        // })
        if (res.data.type !== 3) {
          // this.form.mvUrl = res.data.workContentUrl
          this.imageUrl =
            "https://aibiboat.oss-cn-shanghai.aliyuncs.com/" +
            res.data.workContentUrl +
            "?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto";
          setTimeout(() => {
            this.initVideo();
          }, 0);
        }
        this.form = res.data;
        this.dataListLoading = false;
      }
    },
    getParams(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) {
        return decodeURIComponent(r[2]);
      }
      return null;
    },
    initVideo() {
      let that = this;
      let width = this.getParams("width");
      let height = this.getParams("height");
      // console.log(width);
      var player = new TcPlayer("abc", {
        m3u8:
          "https://aibiboat.oss-cn-shanghai.aliyuncs.com/" +
          this.form.workContent, //请替换成实际可用的播放地址
        autoplay: false, //iOS 下 safari 浏览器，以及大部分移动端浏览器是不开放视频自动播放这个能力的
        poster: { style: "stretch", src: that.imageUrl },
        width: width || "100%",
        height: height || "100%",
        //playbackRates: [0.5,1,1.2,1.5,2], // 设置变速播放倍率选项，仅 HTML5 播放模式有效
        // controls:'system', // default 显示默认控件，none 不显示控件，system 移动端显示系统控件 备注：如果需要在移动端使用系统全屏，就需要设置为system。默认全屏方案是使用 Fullscreen API + 伪全屏的方式例子
        pausePosterEnabled: true,
        volume: 0.5,
        systemFullscreen: true,
        x5_orientation: 0,
      });
      document.getElementsByClassName("vcp-error-tips")[0].style.fontSize =
        "14px"; // 错误码提示语字体大小，因引入了rem，默认字体大小为100px.8
    },
  },
};
</script>

<style lang="scss" scoped>
.mission-info {
  .title {
    font-size: 24px;
    font-weight: bold;
    height: 30px;
    left: 30px;
  }
  .name {
    display: flex;
    font-size: 16px;
    color: #999;
    .times {
      color: #333;
    }
  }
}
</style>